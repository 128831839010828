import React from "react"
import { graphql } from "gatsby"
import differenceInYears from "date-fns/differenceInYears"

import { BasicSEO } from "../components/seo"
import { MHPageProps } from "../types"
import { FooterNavigation } from "../components/FooterNavigation"
import { MHArticle } from "../components/MHArticle"
import { getExcerpt } from "../excerptHelper"

class BlogPostTemplate extends React.Component<MHPageProps> {
  render() {
    const post = this.props.data.mdx
    const { previous, next } = this.props.pageContext
    const hasDate = post.frontmatter.date !== null
    const date = new Date(post.frontmatter.date)
    const age = differenceInYears(new Date(), date)

    return (
      <>
        <BasicSEO
          social={post.social}
          excerpt={getExcerpt(post, true)}
          ogImage={post.frontmatter.image}
          withPubDate={true}
        />

        <div className="oneColumn n-type-larger">
          <MHArticle
            title={post.frontmatter.title}
            subtitle={post.frontmatter.subtitle}
            date={post.frontmatter.date}
            updatedDate={post.frontmatter.updatedDate}
            age={hasDate ? age : undefined}
            body={post.body}
            unpublished={post.frontmatter.published === false}
            asArticle
          />

          <FooterNavigation
            previous={previous}
            next={next}
            tags={post.frontmatter.tags}
          />
        </div>
      </>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      ...MHExcerpt
      body
      frontmatter {
        title
        subtitle
        date
        tags
        updatedDate
        published
      }
      ...BasicSocial
      ...SocialImage
    }
  }
`
